document.addEventListener('DOMContentLoaded', () => {
        gsap.registerPlugin(ScrollTrigger);
    
        const animateCards = () => {
          gsap.from(".char-card", {
            scrollTrigger: {
              trigger: ".characters-map",
              start: "top 80%",
            },
            y: 100,
            opacity: 0,
            stagger: 0.2,
            duration: 1,
            ease: "power2.out",
          });
        };
    
        animateCards();
});
